import { ReactNode } from "react";

export enum BannerType {
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export type Banner = {
  type: BannerType;
  title: string;
  description: ReactNode;
};
