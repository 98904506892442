import { z } from "zod";

import { ENV } from "../../_common/env-vars";
import { bannersExampleData } from "../../_common/ui/banners/banners-example-data";
import { Banner, BannerType } from "../../_common/ui/banners/types";

export const bannersSchema = z
  .record(z.string(), z.boolean())
  .transform(collapsedBanners =>
    Object.fromEntries(
      Object.entries(collapsedBanners).filter(([k, _]) => bannersData.some(banner => banner.title === k)),
    ),
  );

const banners: Banner[] = [
  {
    type: BannerType.Error,
    title: "Important Notice for Huawei Users",
    description:
      "We’ve identified an issue with App Shielding version 7.2.0 on Huawei devices running HarmonyOS or EMUI OS with the latest Android Security Patch (March 1, 2025) that may cause app crashes. A fix is coming soon. We recommend reverting to version 7.0.3 to prevent disruptions. For details and assistance, please contact support.",
  },
];

export const bannersData: Banner[] = !ENV.TEST ? banners : bannersExampleData;
