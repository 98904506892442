import { useState } from "react";
import { z } from "zod";

import { Alert } from "../alert/alert";
import { Banner } from "./types";

function getInitialCollapsedState(
  localStorageKey: string,
  schema: z.ZodEffects<z.ZodRecord<z.ZodString, z.ZodBoolean>>,
): Record<string, boolean> {
  const record = localStorage.getItem(localStorageKey);
  let collapsed = {};

  if (record) {
    try {
      collapsed = schema.parse(JSON.parse(record));
    } catch (e) {
      localStorage.removeItem(localStorageKey);
      return {};
    }
  }

  return collapsed;
}

interface BannerProps {
  data: Banner[];
  schema: z.ZodEffects<z.ZodRecord<z.ZodString, z.ZodBoolean>>;
  localStorageKey: string;
}

export const Banners = ({ localStorageKey, data, schema }: BannerProps) => {
  const [collapsed, setCollapsed] = useState(() => getInitialCollapsedState(localStorageKey, schema));

  return data.map(({ type, title, description }) => (
    <Alert
      key={title}
      type={type}
      title={title}
      description={description}
      onCollapse={() => {
        setCollapsed(collapsed => {
          const newState = {
            ...collapsed,
            [title]: !collapsed[title],
          };

          localStorage.setItem(localStorageKey, JSON.stringify(newState));

          return newState;
        });
      }}
      collapsed={collapsed[title]}
    />
  ));
};
