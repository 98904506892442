import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { Banner, BannerType } from "./types";

export const bannersExampleData: Banner[] = [
  {
    type: BannerType.Info,
    title: "Some title 1",
    description: (
      <Typography>
        Some description with <Link to="/link">link</Link>
      </Typography>
    ),
  },
  {
    type: BannerType.Warning,
    title: "Some title 2",
    description: "Some description 2",
  },
  {
    type: BannerType.Error,
    title: "Some title 3",
    description: "Some description 3",
  },
];
