import { Box } from "@mui/material";

import { useTranslate } from "../../_common/i18n/hooks/use-translate.hook";
import { Banners } from "../../_common/ui/banners/banners";
import { Layout } from "../../_common/ui/layout/layout";
import { bannersData, bannersSchema } from "./banners-data";
import { OnPremShieldersList } from "./components/on-prem-shielders-list";
import { BANNERS_LOCAL_STORAGE_KEY } from "./constants";

export const OnPremShieldersPage = () => {
  const t = useTranslate();

  return (
    <Layout title={t("component.on-prem-shielders.layout.content.title")}>
      <Box display="flex" flexDirection="column" width="100%" gap={2}>
        <Banners localStorageKey={BANNERS_LOCAL_STORAGE_KEY} data={bannersData} schema={bannersSchema} />
        <OnPremShieldersList />
      </Box>
    </Layout>
  );
};
